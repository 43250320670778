// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_root_5fba7a25 {\n  width: 100%;\n  margin: auto;\n}\n\n.select_label_34109e5f {\n  margin-bottom: 2px;\n  display: block;\n}\n\n.select_label_34109e5f.select_error_ea2725b0 {\n  color: var(--rowlfRed);\n}\n\n.select_asterisk_dc80c434 {\n  color: var(--rowlfRed);\n}\n\ndiv.select_errorMessage_c56c9bdf {\n  color: var(--rowlfRed);\n  font-size: 12px;\n  margin-top: 8px;\n  min-height: 20px;\n  font-weight: 400;\n  text-align: left;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/inputs/select/select.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".root {\n  width: 100%;\n  margin: auto;\n}\n\n.label {\n  margin-bottom: 2px;\n  display: block;\n}\n\n.label.error {\n  color: var(--rowlfRed);\n}\n\n.asterisk {\n  color: var(--rowlfRed);\n}\n\ndiv.errorMessage {\n  color: var(--rowlfRed);\n  font-size: 12px;\n  margin-top: 8px;\n  min-height: 20px;\n  font-weight: 400;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "select_root_5fba7a25",
	"label": "select_label_34109e5f",
	"error": "select_error_ea2725b0",
	"asterisk": "select_asterisk_dc80c434",
	"errorMessage": "select_errorMessage_c56c9bdf"
};
export default ___CSS_LOADER_EXPORT___;
