import { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  TextField,
} from '@pumpkincare/shared/ui';

import { useVetClinics } from '../../vet-clinics-query';

import styles from './vet-clinic-search.module.css';

const NO_RESULTS_TITLE_HIDE_FORM = "I don't see my vet clinic on this list";

function VetClinicSearch(props) {
  const {
    onChange,
    onSelect,
    onClear,
    disabled,
    onEnterInfo,
    placeholder,
    label,
    canShowForm,
    defaultValue,
    zipCode,
  } = props;

  const searchInputRef = useRef();
  const searchInputHasFocusRef = useRef(false);
  // Controls if we should display the result list or the empty state
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isFetching } = useVetClinics({
    searchTerm,
    zipCode,
    enabled: !disabled,
  });

  function setInputValue(value) {
    searchInputRef.current.value = value;
  }

  const debouncedTermChange = useDebouncedCallback(({ target: { value } }) => {
    if (value) {
      setSearchTerm(value);
    }

    onChange(value);

    if (value) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, 500);

  function handleClearIconClick() {
    setIsOpen(false);
    setInputValue('');

    onClear();
  }

  function handleListItemClick(event, item) {
    setIsOpen(false);
    setInputValue(item.vet_name);

    onSelect(item);
  }

  function handleEnterInfo() {
    setIsOpen(false);
    onEnterInfo({ value: searchInputRef.current.value });
  }

  function handleFocus() {
    searchInputHasFocusRef.current = true;
  }

  function handleBlur() {
    searchInputHasFocusRef.current = false;
  }

  function showResults() {
    if (!isOpen || (isFetching && !data.length)) {
      return null;
    }

    if (data.length) {
      return (
        <div className={styles.paper}>
          <ul className={styles.resultsList}>
            {data.map((item, index) => {
              return (
                <div
                  role='button'
                  className={styles.resultsItem}
                  key={index}
                  onClick={event => handleListItemClick(event, item)}
                  data-testid='option'
                >
                  <Body1 className={styles.resultsPrimary}>{item.vet_name}</Body1>
                  <LegalBody className={styles.resultsSecondary}>
                    {item.normalized_addr}
                  </LegalBody>
                </div>
              );
            })}
          </ul>
        </div>
      );
    }

    if (!canShowForm) {
      const item = { vet_name: NO_RESULTS_TITLE_HIDE_FORM };

      return (
        <div className={styles.paper}>
          <ul className={styles.noResultsList}>
            <div
              role='button'
              className={styles.noResultsListItem}
              onClick={event => handleListItemClick(event, item)}
            >
              <LegalBody className={styles.noResultsPrimary}>
                {NO_RESULTS_TITLE_HIDE_FORM}
              </LegalBody>

              <LegalBody className={styles.noResultsSecondary}>
                {'No matching vet clinics found'}
              </LegalBody>
            </div>
          </ul>
        </div>
      );
    }

    return (
      <div className={styles.paper}>
        <>
          <ul className={styles.noResultsList}>
            <div key={'no-result'} className={styles.noResultsListItem}>
              <Body1 className={styles.noResultsPrimary}>
                {'No Matching Vet Found'}
              </Body1>

              <LegalBody className={styles.noResultsSecondary}>
                {'No worries! You can enter your vet info manually'}
              </LegalBody>
            </div>
          </ul>

          <Body2
            className={styles.ctaTitle}
          >{`Don't see your vet? No problem!`}</Body2>

          <button
            className={classNames(ButtonStyles.baseButton, styles.cta)}
            onClick={handleEnterInfo}
          >
            Enter My Vet's Info
          </button>
        </>
      </div>
    );
  }

  const isSearch = !searchInputRef.current || !searchInputRef.current.value;

  return (
    <div className={classNames(styles.root, props.className)}>
      <div className={styles.width}>
        <TextField
          label={label}
          placeholder={placeholder}
          defaultValue={defaultValue}
          inputRef={searchInputRef}
          onChange={debouncedTermChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={disabled}
          classes={{ container: styles.textField }}
          endAdornment={{
            icon: isSearch ? 'search' : 'close',
            onIconClick: isSearch ? () => {} : handleClearIconClick,
            isLoading: searchInputHasFocusRef.current && isFetching,
          }}
          data-testid='vet-clinic-search'
        />

        {showResults()}
      </div>
    </div>
  );
}

VetClinicSearch.defaultProps = {
  disabled: false,
  defaultValue: '',
  onChange: () => {},
  onSelect: () => {},
  onClear: () => {},
  onEnterInfo: () => {},
  canShowForm: true,
  placeholder: 'e.g. Ark Animal, Clinic',
};

VetClinicSearch.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  onEnterInfo: PropTypes.func,
  canShowForm: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  zipCode: PropTypes.string,
};

export default VetClinicSearch;
