import { default as ReactSelect } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
import styles from './select.module.css';

function getColor(state, error, isText) {
  if (error) return 'var(--rowlfRed)';
  if (state.isFocused) return 'var(--bluesCluesBlue)';
  if (isText) return 'var(--scoutNavy)';
  return 'var(--pusheenGray)';
}

function getBackgroundColor(isSelected, isFocused) {
  if (isFocused) return 'var(--peteyBlue)';
  if (isSelected) return '#eef0fe80'; // rgba(var(--peteyBlue), 0.5) did not work
  return 'none';
}

function Select(props) {
  const {
    classes,
    customStyles,
    disabled,
    defaultValue,
    label,
    id,
    required,

    error: { errorMessage = '', hasError },

    ...restInputProps
  } = props;

  // react-select uses emotion for styling
  const selectStyles = {
    input: () => ({
      transform: 'translateY(-50%)',
      top: '50%',
      color: 'var(--scoutNavy)',
      position: 'absolute',
      width: '100%',
      padding: '0px',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      cursor: 'text !important',
    }),
    // reset styles for valueContainer
    valueContainer: () => ({
      minHeight: '22px',
    }),
    menu: provided => ({
      ...provided,
      width: '100%',
      maxHeight: '240px',
      marginTop: '4px',
      marginBottom: '8px',
      zIndex: 10000,
      overflowY: 'scroll',
    }),
    loadingIndicator: provided => ({
      ...provided,
      position: 'absolute',
      right: '32px',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    placeholder: () => ({
      position: 'absolute',
      width: '100%',
      cursor: 'pointer !important',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--gromitGray)',
      ...customStyles.placeholder,
    }),
    singleValue: () => ({
      color: getColor({ hasValue: true }, errorMessage, true),
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      display: 'inline-block',
      ...customStyles.singleValue,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => {
      return {
        width: '24px',
        display: 'inline-block',
        position: 'absolute',
        right: '2px',
        top: '50%',
        transform: state.selectProps.menuIsOpen
          ? 'scaleY(-1) translateY(57%)'
          : 'translateY(-35%)',
        color: errorMessage ? 'var(--rowlfRed)' : 'var(--bluesCluesBlue)',
        cursor: 'pointer !important',
        ...customStyles.dropdownIndicator,
        '&:hover': {
          color: 'var(--bluesCluesBlue)',
        },
      };
    },
    option: (provided, state) => {
      const isSelected = state.getValue().some(value => value.label === state.label);
      return {
        ...provided,
        fontFamily: 'Nunito Sans',
        paddingBottom: '14px',
        paddingTop: '14px',
        color: isSelected ? 'var(--bluesCluesBlue)' : 'var(--scoutNavy)',
        fontWeight: isSelected ? '800' : '600',
        background: getBackgroundColor(isSelected, state.isFocused),
        cursor: 'pointer',
      };
    },
    control: (provided, state) => ({
      borderWidth: state.isFocused || !!errorMessage ? '2px' : '1px',
      borderStyle: 'solid',
      borderColor: getColor(state, errorMessage),
      borderRadius: '8px',
      padding: state.isFocused ? '11px 10px' : '12px 11px',
      display: 'flex',
      background: 'var(--zeroWhite)',
      ...customStyles.control,
      '&:hover': {
        border: '2px solid var(--bluesCluesBlue)',
        padding: '11px 10px',
      },
    }),
  };

  const labelClassName = classNames(
    styles.label,
    classes.label,
    Typography.legalBody,
    {
      [styles.error]: errorMessage || hasError,
    }
  );

  return (
    <div className={classNames(styles.root, classes.root)}>
      <label htmlFor={id} className={labelClassName}>
        {label}
        {required ? <span className={styles.asterisk}> *</span> : null}
      </label>

      <ReactSelect
        isDisabled={disabled}
        styles={selectStyles}
        onInputChange={() => {}}
        controlShouldRenderValue
        isClearable={false}
        label={label}
        defaultValue={defaultValue}
        aria-label={label}
        inputId={id}
        {...restInputProps}
      />

      {errorMessage ? (
        <LegalBody className={styles.errorMessage}>{errorMessage}</LegalBody>
      ) : null}
    </div>
  );
}

Select.defaultProps = {
  isSearchable: false,
  classes: {},
  disabled: false,
  customStyles: {},
  components: {},
  label: '',
  autoFocus: false,
  tabIndex: 0,
  onChange: () => {},
  error: {},
  required: false,
};

Select.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.string,

  // ...restInputProps you may need (or are required)
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  components: PropTypes.object,
  hideSelectedOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.any,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
  error: PropTypes.shape({
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
  }),
  required: PropTypes.bool,
};

export default Select;
